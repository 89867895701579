<template>
    <div class="flex justify-center items-center  mt-10">
        <div class="bg-white p-2 rounded-lg w-6/12 shadow-sm">
            <div class="text-center my-4">
                <h2 class="text-lg font-bold">
                    Editar incidente
                </h2>
            </div>
            <form>
              <div class="flex flex-wrap content-around">
                  <div class="w-full flex flex-col items-center py-5 px-2 ">
                      <div class="px-2 w-full flex flex-col">
                      </div>
                      <div class="px-2 w-full flex flex-col">
                        <div class="mb-4" style="width: 100% !important;">
                            <label class="font-medium text-sm">Fallo</label>
                            <Dropdown
                              v-model="model.base"
                              :options="fallos"
                              class="w-full"
                              optionValue="id"
                              optionLabel="observacion"
                              placeholder="Seleccione"
                              >
                                <template #value="slotProps">
                                  <div class="country-item country-item-value" v-if="slotProps.value">
                                    <div v-if="fallos.find(a => a.id === slotProps.value)">
                                      <div>{{ fallos.find(a => a.id === slotProps.value).observacion }}</div>
                                    </div>
                                  </div>
                                  <span v-else>
                                    {{slotProps.placeholder}}
                                  </span>
                                </template>
                                <template #option="slotProps">
                                  <div class="flex">
                                    <div class="w-2/12">
                                      <p style="color: #7F7E81" class="text-xs">
                                        ID:
                                      </p>
                                      <p class="text-normal">
                                        {{ slotProps.option.id }}
                                      </p>
                                    </div>
                                    <div class="w-5/12">
                                      <p style="color: #7F7E81" class="text-xs">
                                        Observaciones:
                                      </p>
                                      <p class="text-normal">
                                        {{ slotProps.option.observacion.slice(0, 40) }}...
                                      </p>
                                    </div>
                                  </div>
                                </template>
                            </Dropdown>
                            <MessageError :text="errors.base"/>
                        </div>
                      </div>
                      <div class="px-2 w-full flex flex-col">
                        <div class="mb-4">
                            <label class="font-medium text-sm">Observaciones</label>
                            <Textarea
                            rows="3" cols="30"
                            class="w-full"
                            inputClass="w-full"
                            placeholder="Escribir..."
                            v-model="model.observacion"
                            />
                            <MessageError :text="errors.observacion"/>
                        </div>
                      </div>
                      <div class="flex gap-4 px-2">
                        <div class="mb-4">
                          <label class="font-medium text-sm">Fecha de notificacion</label>
                          <input class="p-inputtext w-full"
                                type="datetime-local"
                                v-model="model.fechaNotificacion"
                          >
                          <MessageError :text="errors.fechaNotificacion"/>
                        </div>
                        <div class="mb-4">
                          <label class="font-medium text-sm">Fecha de vencimiento</label>
                          <input class="p-inputtext w-full"
                                type="datetime-local"
                                v-model="model.fechaVencimiento"
                          >
                          <MessageError :text="errors.fechaVencimiento"/>
                        </div>
                      </div>
                      <div class="px-2 w-full">
                        <div style="max-height: 150px; overflow-y: auto;">
                          <div class="flex justify-between" v-for="(item, key) in adjuntos" :key="key">
                            <div class="flex mt-2">
                              <i class="pi pi-file-pdf mr-2"></i>
                              <p>{{ item.fileName }}</p>
                            </div>
                            <div>
                              <button type="button" @click="verPdf(item.urlPath)"><i class="pi pi-eye mr-2 text-blue-600"></i></button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="px-2 mt-4 w-full flex flex-col">
                        <button type="button" label="Show" icon="pi pi-external-link" @click="openMaximizable" class="w-full rounded-full py-2 bg-white border border-solid border-gray-400">
                          Agregar nuevo soporte
                        </button>
                        <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
                            <div>
                              <div>
                                <label class="text-sm" for="titulo">Titulo</label>
                                <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
                                class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                              </div>
                              <div class="my-2">
                                <label class="text-sm">Adjunte el documento</label>
                                <div class="mt-2">
                                  <button type="button" class="w-full">
                                    <FilePond ref="pond"/>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <template class="text-center" #footer>
                                <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus />
                            </template>
                        </Dialog>
                      </div>
                  </div>
                  <div class="w-full flex justify-between px-2 mb-9">
                      <Button  label="Cancelar"
                          class="bg-blue-900 p-button-outlined"
                          style="width: 244px;"
                          @click="$router.go(-1)"
                      />
                      <Button  label="Editar"
                          class="bg-blue-900"
                          style="width: 244px;"
                          @click="update"
                      />
                  </div>
              </div>
            </form>
        </div>
    </div>
</template>
<script>
import storeIncidencias from '../../../../store/incidencia.store'
import storeFallos from '../../../../store/fallos.store'
import filepondStore from '../../../../store/filepond.store'
import FilePond from '../../../uploadFilePoundNew/index.vue'
import PdfService from '../../../../services/pdf.service'
import { computed, onMounted, ref } from 'vue'
import Swal from 'sweetalert2'
import { useRoute, useRouter } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import storeTutela from '@/apps/pharmasan/juridica/procesosJuridicos/store/tutela.store'
import * as yup from 'yup'
import { filter } from 'lodash'
export default {
  name: 'editarIncidencia',
  components: {
    FilePond
  },
  setup () {
    /** hooks **/
    onMounted(async () => {
      await storeFallos.dispatch('getPaginate')
      await storeTutela.dispatch('getPaginate')
      // await storeIncidencias.dispatch('getTiposIncidencia')
      await storeIncidencias.dispatch('getPaginate')
      id.value = route.params.id
      if (!incidencia.value.id) {
        storeIncidencias.dispatch('findIncidencia', route.params.id).then((data) => {
          model.tipo = data.tipo
          model.base = data.base
          model.observacion = data.observacion
          model.fechaNotificacion = data.fechaNotificacion
          model.fechaVencimiento = data.fechaVencimiento
          adjuntos.value = data.paths
        })
      }
    })
    /** intancias **/
    const _PdfService = new PdfService()
    const router = useRouter()
    const route = useRoute()
    const pond = ref()
    const id = ref(null)
    const title = ref('')
    const bases = ref([])
    const displayMaximizable = ref(false)
    const optionLabelDinamicCase = ref('')
    const adjuntos = ref([])
    const idTutela = route.query.idTutela

    /** computed **/
    const incidencia = computed(() => {
      return storeIncidencias.getters.incidencia
    })
    const fallos = computed(
      () => storeFallos.getters.fallos.rows
    )
    const adjunto = computed(() => filepondStore.getters.adjunto)
    const tipos = computed(() => storeIncidencias.getters.tipos.rows)

    /** state **/

    const validationSchema = yup.object({
      observacion: yup.string().required('El campo es requerido').label(''),
      tipo: yup.number().nullable().required('El campo es requerido').label(''),
      base: yup.number().nullable().required('El campo es requerido').label(''),
      fechaNotificacion: yup.date().required('Este campo es requerido'),
      fechaVencimiento: yup.date().required('Este campo es requerido')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema
    })
    useField('tipo', null, {
      initialValue: 3
    })
    useField('observacion', null, {
      initialValue: incidencia.value.observacion
    })
    useField('base', null, {
      initialValue: incidencia.value.base
    })
    useField('fechaNotificacion')
    useField('fechaVencimiento')

    /** methods **/

    const update = handleSubmit((values) => {
      if (!values) return console.error('Error => validation')
      storeIncidencias.commit('setUpdateIncidencia', values)
      storeIncidencias.dispatch('updateIncidencia', { id: parseInt(id.value), payload: values }).then(({ data }) => {
        Swal.fire({
          title: 'Exito',
          text: 'Incidente actualizado con exito',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            router.go(-1)
          }
        })
      })
    })
    const verPdf = (path) => {
      _PdfService.ver({ path })
    }
    const openMaximizable = () => {
      displayMaximizable.value = true
    }

    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(async () => {
        await _PdfService.create({
          tipoNombre: 'incidencia',
          tipo: 2,
          base: route.params.id,
          fileName: title.value,
          urlPath: adjunto.value.urlPath
        })
        storeIncidencias.dispatch('findIncidencia', route.params.id).then((data) => {
          model.tipo = data.tipo
          model.base = data.base
          model.observacion = data.observacion
          model.fechaNotificacion = data.fechaNotificacion
          model.fechaVencimiento = data.fechaVencimiento
          adjuntos.value = data.paths
        })
        displayMaximizable.value = false
        title.value = ''
      })
    }

    return {
      incidencia,
      errors,
      model,
      update,
      tipos,
      bases,
      verPdf,
      openMaximizable,
      closeMaximizable,
      displayMaximizable,
      optionLabelDinamicCase,
      fallos,
      adjuntos,
      pond,
      title,
      idTutela,
      filter
    }
  }
}
</script>

<style scoped>

</style>
